import { Component, Prop } from 'vue-property-decorator'
import { Field } from '../field'
import { getObjectValueByPath } from '@/utils/vuetify/helpers'

@Component
export class Linked extends Field {
  @Prop({ type: Object, default: () => ({}) }) readonly properties!: any;

  openForm () {
    this.$emit('nested:form')
  }

  get isCreated () {
    const { value, properties: { itemValue = 'id' } } = this
    return Boolean(getObjectValueByPath(value, itemValue))
  }

  get success () {
    const { value, isCreated } = this
    return isCreated || (value && 'serialized' in value)
  }

  get title () {
    const { success } = this
    if (!success) return undefined

    const { properties: { itemText }, value } = this
    return itemText ? getObjectValueByPath(value, itemText) : value
  }

  get icon () {
    const {
      success,
      properties: {
        icon = { create: 'mdi-plus-circle-outline', edit: 'mdi-pencil' },
      },
    } = this

    return icon[success ? 'edit' : 'create']
  }
}
